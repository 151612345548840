<template>
    <section>
        <div v-show="!showZonas && !showNuevaDireccion">
            <div class="row d-flex a-center j-center ">
                <div class="my-5 f-600 f-20">
                    Espera...
                </div>
            </div>

            <div class="row d-flex a-center j-center mb-3">
                <div>
                    <img :src="funImagenGeneral(44)" alt="" />
                </div>
            </div>
            <div class="row d-flex a-center j-center">
                <div class="col-4 text-center">
                    <p class="">
                        {{ mensaje }}
                    </p>
                </div>
            </div>
            <div class="row mx-0 j-center py-2">
                <div class="col-12 px-0 mx-0 bg-linea mt-2 mb-2" />
                <button
                v-if="botonZonas"
                type="button"
                class="btn-outline mx-2 d-flex a-center j-center"
                @click="verZonasCobertura"
                v-text="'Ver las zonas de cobertura'"
                />

                <direcciones v-if="botonDirecciones" ref="componentDirecciones" geocerca />

                <button
                v-show="botonZonas || botonDirecciones"
                type="button"
                class="btn-adicional d-flex a-center j-center mx-2"
                @click="agregarDireccion"
                v-text="'Añadir dirección'"
                />
            </div>
        </div>

        
        <div v-if="showNuevaDireccion" class="row j-center">
            <div class="col-8">
                <nueva-direccion @init="initView" />
            </div>
        </div>
        <div v-if="showZonas" class="row j-center">
            <div class="col-8">
                <mapaCobertura @init="initView" />
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    components:{
        nuevaDireccion: () => import('./agregarDireccion.vue'),
        mapaCobertura: () => import('./mapaZonasCobertura.vue'),
    },
    data(){
        return {
            mensaje:'',
            botonDirecciones:false,
            botonZonas:false,
            showNuevaDireccion:false,
            showZonas:false,
        }
    },
    computed:{
        ...mapGetters({
            response:'asignarTiendas/respuesta'
        })    
    },
    mounted(){
        this.initView()
    },
    methods:{
        agregarDireccion(){
            this.showNuevaDireccion = true
            this.showZonas = false
        },
        verZonasCobertura(){
            //return
            this.showZonas = true
            this.showNuevaDireccion = false
        },
        initView(){

            this.showNuevaDireccion = false
            this.showZonas = false

            switch (this.response.code){
            case 1:
                this.mensaje = 'Por favor agregue una direccion de entrega.'
                this.botonZonas = true
                this.botonDirecciones = false
                break; 
            case 2:
            case 4:
            case 5:
                this.mensaje = 'Lo sentimos! Error interno, pongase en contacto con el administrador.'
                this.botonZonas = false
                this.botonDirecciones = false
                break; 
            case 3:
                this.mensaje = 'Lo sentimos pero no tenemos cobertura para tu ubicación, intenta con otra dirección.'
                this.botonZonas = true
                this.botonDirecciones = true
                break;
            
            default:
                this.mensaje = 'Lo sentimos! Falta parametrizacion.'
                this.botonZonas = false
                this.botonDirecciones = false
                break;
            }

            this.$store.commit('asignarTiendas/setBanderaAdvertencia',false)
        },
    }
}
</script>